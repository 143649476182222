<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row class="mb-3" v-if="$email() !== 'Atoria@icdexchange.com'">
      <b-col sm>
        <div>
          <button class="btn btn-success m-2" type="button" @click="exportToday">Export Today</button>
        </div>
      </b-col>
    </b-row>
    <h6>Today</h6>
    <br>
    <div class="vutable-body-wrapper">
      <table class="vuetable ui blue selectable unstackable celled table fixed">
        <thead>
          <tr>
            <th colspan="1"></th>
            <th colspan="6" class="text-center">Buy</th>
            <th colspan="6" class="text-center">Sell</th>
          </tr>
          <tr>
            <th class="text-center">Time</th>
            <th class="text-center">BTC</th>
            <th class="text-center">ETH</th>
            <th class="text-center">USDT</th>
            <th class="text-center">BNB</th>
            <th class="text-center">TKO</th>
            <th class="text-center">Frequency</th>
            <th class="text-center">BTC</th>
            <th class="text-center">ETH</th>
            <th class="text-center">USDT</th>
            <th class="text-center">BNB</th>
            <th class="text-center">TKO</th>
            <th class="text-center">Frequency</th>
          </tr>
        </thead>
        <tbody class="vuetable-body">
          <tr v-for="(item, index) in today" :key="index">
            <td class="text-center">{{ item.label }}</td>
            <td class="text-right">{{ item.buy_btc }}</td>
            <td class="text-right">{{ item.buy_eth }}</td>
            <td class="text-right">{{ item.buy_usdt }}</td>
            <td class="text-right">{{ item.buy_bnb }}</td>
            <td class="text-right">{{ item.buy_tko }}</td>
            <td class="text-right">{{ item.buy_frequency }}</td>
            <td class="text-right">{{ item.sell_btc }}</td>
            <td class="text-right">{{ item.sell_eth }}</td>
            <td class="text-right">{{ item.sell_usdt }}</td>
            <td class="text-right">{{ item.sell_bnb }}</td>
            <td class="text-right">{{ item.sell_tko }}</td>
            <td class="text-right">{{ item.sell_frequency }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <br>
    <b-row>
      <b-col>
        <b-form-group id="month"
            label="Bulan"
            label-for="month">
          <b-form-select id="month"
            v-model="month"
            :options="[
                      {value: '01', text: 'Januari'},
                      {value: '02', text: 'Februari'},
                      {value: '03', text: 'Maret'},
                      {value: '04', text: 'April'},
                      {value: '05', text: 'Mei'},
                      {value: '06', text: 'Juni'},
                      {value: '07', text: 'Juli'},
                      {value: '08', text: 'Agustus'},
                      {value: '09', text: 'September'},
                      {value: '10', text: 'Oktober'},
                      {value: '11', text: 'November'},
                      {value: '12', text: 'Desember'}
                    ]"
            aria-describedby="categoryFeedback"
            autofocus />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group id="year"
                      label="Tahun"
                      label-for="year">
          <b-form-select id="year"
            v-model="year"
            :options="[
                      {value: '2018', text: '2018'},
                      {value: '2019', text: '2019'},
                      {value: '2020', text: '2020'},
                      {value: '2021', text: '2021'},
                      {value: '2022', text: '2022'},
                      {value: '2023', text: '2023'},
                      {value: '2024', text: '2024'},
                      {value: '2025', text: '2025'}
                    ]"
            aria-describedby="categoryFeedback"
            autofocus />
        </b-form-group>
      </b-col>
    </b-row>
    <div class="vutable-body-wrapper">
      <table class="vuetable ui blue selectable unstackable celled table fixed">
        <thead>
          <tr>
            <th colspan="1"></th>
            <th colspan="6" class="text-center">Buy</th>
            <th colspan="6" class="text-center">Sell</th>
          </tr>
          <tr>
            <th class="text-center">Time</th>
            <th class="text-center">BTC</th>
            <th class="text-center">ETH</th>
            <th class="text-center">USDT</th>
            <th class="text-center">BNB</th>
            <th class="text-center">TKO</th>
            <th class="text-center">Frequency</th>
            <th class="text-center">BTC</th>
            <th class="text-center">ETH</th>
            <th class="text-center">USDT</th>
            <th class="text-center">BNB</th>
            <th class="text-center">TKO</th>
            <th class="text-center">Frequency</th>
          </tr>
        </thead>
        <tbody class="vuetable-body">
          <tr v-for="(item, index) in monthly" :key="index">
            <td class="text-center">{{ item.label }}</td>
            <td class="text-right">{{ item.buy_btc }}</td>
            <td class="text-right">{{ item.buy_eth }}</td>
            <td class="text-right">{{ item.buy_usdt }}</td>
            <td class="text-right">{{ item.buy_bnb }}</td>
            <td class="text-right">{{ item.buy_tko }}</td>
            <td class="text-right">{{ item.buy_frequency }}</td>
            <td class="text-right">{{ item.sell_btc }}</td>
            <td class="text-right">{{ item.sell_eth }}</td>
            <td class="text-right">{{ item.sell_usdt }}</td>
            <td class="text-right">{{ item.sell_bnb }}</td>
            <td class="text-right">{{ item.sell_tko }}</td>
            <td class="text-right">{{ item.sell_frequency }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <br>
    <b-row class="mb-3" v-if="$email() !== 'Atoria@icdexchange.com'">
      <b-col sm>
        <div>
          <button class="btn btn-success m-2" type="button" @click="exportTable">Export</button>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm="12" md="6" lg="6">
        <div>
          <b-form-group
            class="mb-3"
            label="Date :"
            label-for="daterange"
          >
            <b-input-group
            >
              <b-input-group-prepend is-text>
                <i class="fa fa-calendar"></i>
              </b-input-group-prepend>
              <month-range-picker id="daterange" :start="startDate" :end="endDate" @picker="doDateFilter"></month-range-picker>
              <b-input-group-append>
                <b-button type="button" variant="secondary" @click="resetDateFilter">Reset</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <vuetable ref="vuetable"
      @vuetable:load-error="handleLoadError"
      :api-url="apiUrl"
      :http-options="HttpOptions"
      :fields="fields"
      pagination-path=""
      :header-rows="headerRows"
      :muti-sort="true"
      :sort-order="sortOrder"
      :append-params="moreParams"
      @vuetable:pagination-data="onPaginationData"
    >
      <template slot="date-slot" slot-scope="prop">
        <tr rowspan="4">
          <td style="text-align: center; vertical-align: middle;">{{ prop.rowData.date }}</td>
        </tr>
      </template>
      <template slot="label-slot" slot-scope="prop">
        <div v-for="(item, index) in prop.rowData.crypto_analyst_detail" :key="index">
          <span class="text-center">{{ item.label }}</span> <br> <br>
        </div>
      </template>
      <template slot="buy_btc-slot" slot-scope="prop">
        <div v-for="(item, index) in prop.rowData.crypto_analyst_detail" :key="index">
          <span class="text-center">{{ item.buy_btc }}</span> <br> <br>
        </div>
      </template>
      <template slot="buy_eth-slot" slot-scope="prop">
        <div v-for="(item, index) in prop.rowData.crypto_analyst_detail" :key="index">
          <span class="text-center">{{ item.buy_eth }}</span> <br> <br>
        </div>
      </template>
      <template slot="buy_usdt-slot" slot-scope="prop">
        <div v-for="(item, index) in prop.rowData.crypto_analyst_detail" :key="index">
          <span class="text-center">{{ item.buy_usdt }}</span> <br> <br>
        </div>
      </template>
      <template slot="buy_bnb-slot" slot-scope="prop">
        <div v-for="(item, index) in prop.rowData.crypto_analyst_detail" :key="index">
          <span class="text-center">{{ item.buy_bnb }}</span> <br> <br>
        </div>
      </template>
      <template slot="buy_tko-slot" slot-scope="prop">
        <div v-for="(item, index) in prop.rowData.crypto_analyst_detail" :key="index">
          <span class="text-center">{{ item.buy_tko }}</span> <br> <br>
        </div>
      </template>
      <template slot="buy_frequency-slot" slot-scope="prop">
        <div v-for="(item, index) in prop.rowData.crypto_analyst_detail" :key="index">
          <span class="text-center">{{ item.buy_frequency }}</span> <br> <br>
        </div>
      </template>
      <template slot="sell_btc-slot" slot-scope="prop">
        <div v-for="(item, index) in prop.rowData.crypto_analyst_detail" :key="index">
          <span class="text-center">{{ item.sell_btc }}</span> <br> <br>
        </div>
      </template>
      <template slot="sell_eth-slot" slot-scope="prop">
        <div v-for="(item, index) in prop.rowData.crypto_analyst_detail" :key="index">
          <span class="text-center">{{ item.sell_eth }}</span> <br> <br>
        </div>
      </template>
      <template slot="sell_usdt-slot" slot-scope="prop">
        <div v-for="(item, index) in prop.rowData.crypto_analyst_detail" :key="index">
          <span class="text-center">{{ item.sell_usdt }}</span> <br> <br>
        </div>
      </template>
      <template slot="sell_bnb-slot" slot-scope="prop">
        <div v-for="(item, index) in prop.rowData.crypto_analyst_detail" :key="index">
          <span class="text-center">{{ item.sell_bnb }}</span> <br> <br>
        </div>
      </template>
      <template slot="sell_tko-slot" slot-scope="prop">
        <div v-for="(item, index) in prop.rowData.crypto_analyst_detail" :key="index">
          <span class="text-center">{{ item.sell_tko }}</span> <br> <br>
        </div>
      </template>
      <template slot="sell_frequency-slot" slot-scope="prop">
        <div v-for="(item, index) in prop.rowData.crypto_analyst_detail" :key="index">
          <span class="text-center">{{ item.sell_frequency }}</span> <br> <br>
        </div>
      </template>
    </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <vuetable-pagination-info ref="paginationInfo"
      ></vuetable-pagination-info>

      <vuetable-pagination ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
  </div>
</template>

<script>
  import accounting from 'accounting'
  import Vue from 'vue'
  import Vuetable from 'vuetable-2/src/components/Vuetable'
  import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
  import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
  import VuetableRowHeader from 'vuetable-2/src/components/VuetableRowHeader.vue'
  import RowHeader from './RowHeader'

  let startDate = '';
  let endDate = '';
  Vue.use(Vuetable);

  export default {
    components: {
      Vuetable,
      VuetablePagination,
      VuetablePaginationInfo
    },
    prop: {
      rowData: {
        type: Object,
        required: true
      },
      rowIndex: {
        type: Number
      }
    },
    watch: {
      month () {
        this.$http.get(`crypto-analyst-monthly?date=${this.year}-${this.month}`).then((res) => {
          this.monthly = res.data.data
        })
      },
      year () {
        this.$http.get(`crypto-analyst-monthly?date=${this.year}-${this.month}`).then((res) => {
          this.monthly = res.data.data
        })
      }
    },
    data() {
      return {
        today: [],
        monthly: [],
        month: '01',
        year:'2021',
        startDate: '',
        endDate: '',
        user: [],
        isLoading: false,
        largeModal: false,
        filterText: '',
        message: '',
        dateDetail: {
          start_from: '',
          end_from: ''
        },
        headerRows: [RowHeader, VuetableRowHeader],
        errors: {
          code: '',
          message: '',
          status: ''
        },
        apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `crypto-analyst`,
        HttpOptions: {
          headers: {
            'Accept' : 'application/json',
            'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
          }
        },
        sortOrder: [
          {
            field: 'date',
            sortField: 'date',
            direction: 'desc'
          }
        ],
        moreParams: {},
        fields: [
          {
            name: 'date-slot',
            sortField: 'date',
            title: 'Date',
            titleClass: 'center align'
          },
          {
            name: 'label-slot',
            title: 'Time',
            titleClass: 'center align'
          },
          {
            name: 'buy_btc-slot',
            title: 'BTC',
            titleClass: 'center align'
          },
          {
            name: 'buy_eth-slot',
            title: 'ETH',
            titleClass: 'center align'
          },
          {
            name: 'buy_usdt-slot',
            title: 'USDT',
            titleClass: 'center align'
          },
          {
            name: 'buy_bnb-slot',
            title: 'BNB',
            titleClass: 'center align'
          },
          {
            name: 'buy_tko-slot',
            title: 'TKO',
            titleClass: 'center align'
          },
          {
            name: 'buy_frequency-slot',
            title: 'Frequency',
            titleClass: 'center align'
          },
          {
            name: 'sell_btc-slot',
            title: 'BTC',
            titleClass: 'center align'
          },
          {
            name: 'sell_eth-slot',
            title: 'ETH',
            titleClass: 'center align'
          },
          {
            name: 'sell_usdt-slot',
            title: 'USDT',
            titleClass: 'center align'
          },
          {
            name: 'sell_bnb-slot',
            title: 'BNB',
            titleClass: 'center align'
          },
          {
            name: 'sell_tko-slot',
            title: 'TKO',
            titleClass: 'center align'
          },
          {
            name: 'sell_frequency-slot',
            title: 'Frequency',
            titleClass: 'center align'
          },
        ]
      }
    },
    created() {
      this.$http.get(`crypto-analyst-monthly?date=${this.year}-${this.month}`).then((res) => {
        this.monthly = res.data.data
      })

      this.$http.get(`crypto-analyst-today`).then((res) => {
        this.today = res.data.data
      })
    },
    methods: {
      statusField(value) {
        return value == 0 ? 'Sedang Diproses' : (value == 1 ? 'Berhasil' : (value == 2 ? 'Gagal' : '-'))
      },
      goldField(value) {
        return value + ' Gram'
      },
      doFilter () {
        this.$events.$emit('filter-set', this.filterText)
      },
      resetFilter () {
        this.filterText = ''  // clear the text in text input
        this.$events.$emit('filter-reset')
      },
      exportTable() {
        this.isLoading = true
        if (this.startDate != '' && this.endDate != '') {
          startDate = this.startDate;
          endDate = this.endDate;
        }else{
          endDate = this.$setDate.format('YYYY-MM-DD');
          startDate = this.$setDate.subtract(6, 'days').format('YYYY-MM-DD');
        }
        this.$http.get(`data-crypto-analyst/` + startDate + `/` + endDate)
        .then((result) => {
          this.isLoading =false
          const  exportPath = result.data.meta.data;
          window.location.href = exportPath;
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        })
      },
      exportToday() {
        this.isLoading = true
        this.$http.get(`data-crypto-analyst-today`)
        .then((result) => {
          this.isLoading =false
          const  exportPath = result.data.meta.data;
          window.location.href = exportPath;
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        })
      },
      handleLoadError(error) {
        this.errors.code = error.response.data.meta.code;
        this.errors.message = error.response.data.meta.message;
        this.errors.status = error.response.data.meta.code;
        if (this.errors.code == 401) {
          if (localStorage.getItem('access_token') != null) {
            localStorage.removeItem('access_token');
            this.$swal.fire(
              'Your session expired!',
              'Your session has expired. Please login again to access this page!',
              'error'
            ).then(() =>{
              this.$router.push("/login")
            })
          }
        }else if(this.errors.code == 403) {
          this.$router.push("/403")
        }else if(this.errors.code == 500) {
          this.$router.push("/500")
        }
      },
      resetDateFilter () {
        this.startDate = ''
        this.endDate = ''
        this.$events.$emit('date-filter-reset')
      },
      formatNumber (value) {
        return 'Rp.'+accounting.formatNumber(value, 2)
      },
      number(value){
        return value
      },
      onChangePage (page) {
        this.$refs.vuetable.changePage(page)
      },
      onPaginationData (paginationData) {
        this.$refs.pagination.setPaginationData(paginationData)
        this.$refs.paginationInfo.setPaginationData(paginationData)
      },
      onAction (action, data) {
        if (action == "detail-item") {
          this.$router.push({name: 'Detail Jual Emas', params:{id: data.hashed_id}})
        }else if (action == 'check-item') {
          this.$http.put(`detail-withdraw-sell/` + data.hashed_id)
          .then((result) => {
              this.user = result.data.meta.data;
              setInterval(this.check(result.data.meta.data), 5000)
          })
        }else{
          this.isLoading = true
          this.$http.post(`withdraw-set-success`, {
              user: data.hashed_id
          }).then(() => {
              this.isLoading = false
              this.$swal.fire(
                'Success!',
                'Dana Berhasil dikirim!',
                'success'
              ).then(() => {
                location.reload()
              })
          }).catch((error) => {
              if (error.response) {
                  this.isLoading = false
                  this.errors.code = error.response.status;
                  this.errors.message = error.response.data.meta.message;
                  this.errors.status = error.response.data.meta.code;
                  if (this.errors.status  == 400) {
                      this.$swal.fire(
                          'Failed',
                          this.errors.message,
                          'error'
                      ).then(() => {
                          location.reload()
                      })
                  }else if (this.errors.status  == 404) {
                      this.$swal.fire(
                          'Failed',
                          this.errors.message,
                          'error'
                      ).then(() => {
                          location.reload()
                      })
                  }
              }
          })
        }
      },
      onFilterSet (filterText) {
          this.moreParams = {
              'filter': filterText
          }
          Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      doDateFilter(value){
        this.startDate = value.startDate;
        this.endDate = value.endDate;
        this.$events.fire('date-set', [this.startDate, this.endDate])
      },
      onDateSet()  {
        this.start_from = this.startDate;
        this.end_to = this.endDate;
        this.moreParams = {
          'start_from': this.start_from,
          'end_to': this.end_to
        }
        Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onFilterReset () {
          this.moreParams = {}
          Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onDateFilterReset () {
        this.moreParams = {}
        Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      check(user) {
        setInterval(this.$http.post(`check`, {
          bank_id: user.bank.bank_id,
          account_number: user.bank.account_number
        }).then((response) => {
            this.user.bank.owner_name = response.data.data.name
            this.user.bank.account_number = response.data.data.account_number
            this.message = '<h5 style="color:green">' + response.data.meta.message + '</h5>'
        }).catch((error) =>{
          if (error.response) {
            this.message = '<h5 style="color:red">' + error.response.data.meta.message + '</h5>'
          }
        }), 5000)
      }
    },
    mounted() {
      this.$events.$on('filter-set', eventData => this.onFilterSet(eventData))
      this.$events.$on('date-set', eventData => this.onDateSet(eventData))
      this.$events.$on('filter-reset', () => this.onFilterReset())
      this.$events.$on('date-filter-reset', () => this.onDateFilterReset())
    },
  }
</script>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

  footer.modal-footer {
      display: none;
  }

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
